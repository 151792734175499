import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/shared/plugins/vuetify'
import '@/shared/plugins/axios'
import '@/shared/plugins/snackbar'
import '@/shared/plugins/loading'
import '@/shared/plugins/userHistories'
import '@/shared/plugins/ipPlugin'
import '@/shared/plugins/pingPlugin'
import '@/shared/plugins/version'
import Destroy from '@/shared/plugins/DestroyComponent'
import VueCurrencyFilter from 'vue-currency-filter'
import titleMixin from '@/shared/mixins/titleMixin'
import Cache from '@/shared/utils/Cache'

Vue.use(Destroy)
Vue.mixin(titleMixin)

Vue.prototype.$storage = process.env.VUE_APP_ASSETS_URL
Vue.prototype.$api_url = process.env.VUE_APP_API_URL
Vue.prototype.$mode = process.env.VUE_APP_MODE
Vue.prototype.$cache = (new Cache('localStorage'))

Vue.use(VueCurrencyFilter,
  {
    symbol: 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })

new Vue({
  beforeCreate () {
    this.$ipModule.updateIp()
    this.$pingModule.startPing()
    this.$version.startVersioning()
  },
  created () {
    if (localStorage.getItem('_token')) {
      this.$store.dispatch('user/setUser')
    } else {
      window.location.href = '/login'
    }
  },
  router,
  vuetify,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
