import Vue from 'vue'
import Vuex from 'vuex'

import user from '@/shared/store/modules/user'
import snackbar from '@/shared/store/modules/snackbar'
import loading from '@/shared/store/modules/loading'
import userHistories from '@/shared/store/modules/userHistories'
import ipModule from '@/shared/store/modules/ipModule'
import pingModule from '@/shared/store/modules/pingModule'
import version from '@/shared/store/modules/version'
import Exam from '@/app/store/modules/Exam'
import course from '@/app/store/modules/course'
import courseStore from '@/app/store/modules/courseStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    snackbar,
    loading,
    userHistories,
    ipModule,
    pingModule,
    version,
    Exam,
    course,
    courseStore
  }
})
