<template>
  <v-app id="app" style="background-color: #f0f2f5; z-index: 20">
    <div v-if="userData.block_user === 0">
      <img class="over" style="z-index: 0" src="./assets/wallpaper.jpg" />
      <loading style="z-index: 100000000000000" />
      <v-main>
        <appBar />
        <transition name="fade">
          <router-view style="z-index: 1" :key="$route.fullPath"></router-view>
        </transition>
      </v-main>
      <snackbar style="z-index: 10000000" />
      <backTop />
    </div>

    <div v-else-if="userData.block_user === 1">
      <v-dialog :value="true" persistent max-width="700px">
        <v-card>
          <v-card-title class="d-flex justify-center"
            >Acesso a plataforma bloqueado!</v-card-title
          >
          <v-card-text class="d-flex justify-center">
            Seu acesso a plataforma foi bloqueado, por favor envie email para
            ;<a
              style="text-decoration: none"
              href="mailto:financeiro@ceabbrasil.com.br"
            >
              <span class="primary--text font-weight-medium"
                >financeiro@ceabbrasil.com.br</span
              >
            </a>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click.stop="userLogout(true)">Sair</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import appBar from '@/app/components/appBar'
import snackbar from '@/shared/components/snackbar'
import loading from '@/shared/components/loading'
import backTop from './components/backTop'
// import bugCatcher from '@/shared/mixins/bugCatcher'
import { mapState, mapGetters, mapActions } from 'vuex'
// import versionController from '@/shared/components/versionController'

export default {
  // mixins: [bugCatcher],
  title: 'Ceab - "Dando asas ao seu sonho de voar"',
  components: { appBar, snackbar, loading, backTop },
  data: () => ({
    drawer: null,
    purchaseInterval: null,
    ip: ''
  }),
  computed: {
    ...mapGetters('user', ['isLogged']),
    ...mapGetters('user', ['isAdmin']),
    ...mapState('user', ['userData'])
  },
  methods: {
    ...mapActions('user', ['updateUser', 'userLogout']),
    loggedTimeUpdater() {
      this.$api
        .post('/student/user_time_logged', {
          ip: this.$ipModule.getIp()
        })
        .then(res => {
          if (res.data.disconnected === true) {
            this.userLogout(false)
            window.location.reload()
          }
        })
    },
    async purchaseCheck() {
      // Ceab Credits
      await this.$api.get('/student/transactions/check').finally(() => {
        console.log('FInalizou')
        this.updateUser()
      })
    }
  },
  watch: {
    isLogged(val) {
      if (val) {
        setTimeout(() => {
          this.loggedTimeUpdater()
        }, 5000)

        setInterval(() => {
          this.loggedTimeUpdater()
        }, 300000)
      }
    }
  },
  beforeMount() {
    this.purchaseCheck()

    this.purchaseInterval = setInterval(() => {
      this.purchaseCheck()
    }, 1800000)
  },
  beforeDestroy() {
    clearInterval(this.purchaseInterval)
  }
}
</script>

<style>
.over {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
/* #app {
  background-image: url("assets/wallpaper.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
} */
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.15s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
